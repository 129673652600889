export default function () {
  // console.log('modal');
  let $body = $('body'),
    $modalOpen = $body.find('.modal-open--js'),
    $modalClose = $body.find('.modal-close--js'),
    $bgClose = $body.find('.bg-overlay--js'),
    $formConsultation = $body.find('.form-consultation-js'),
    $formConsultationItem = $formConsultation.find('.form-item-input'),
    $phoneInput = $formConsultation.find('#form-phone'),
    lineName,linePhone,hasError,
    $this,
    field = [];
  function formSend(){

    $phoneInput.on("click",function(){
      $phoneInput.mask('+38 (000) 000-00-00');
    });
    $formConsultation.submit(function (e) {
      let actionUrl = $formConsultation.attr('action');
      // console.log(actionUrl);
      e.preventDefault();
      field = [];

      resetError();

      if (validateForm()) {
        $.ajax({
          url: actionUrl,
          type: 'post',
          dataType: 'application/json',
          data: $formConsultation.serialize(),
          success: callbackForm()
        });

      }
      function callbackForm(){
        console.log('form success');
        $body.addClass('show-modal');
        $formConsultation.find('input[data-validate]').val('');
      }
      function resetError() {
        $formConsultationItem.find('.error').removeClass('error');
      }

      function validateForm() {
        hasError = false;
        $formConsultation.find('input#form-name').each(function () {
          $this  = $(this);
          if ($this.val().length < 4) {
            console.log('name error');
            lineName = $this.closest($formConsultationItem);
            lineName.addClass('error');
            setTimeout(function () {
              lineName.removeClass('error')
            }.bind($this), 2000);
            hasError = true;
          }
        });
        $formConsultation.find('input#form-phone').each(function () {
          $this  = $(this);
          if ($this.val().length < 19) {
            console.log('phone error');
            linePhone = $this.closest($formConsultationItem);
            linePhone.addClass('error');
            setTimeout(function () {
              linePhone.removeClass('error')
            }.bind($this), 2000);
            hasError = true;
          }
        });


        return !hasError;
      }

    });
  }
  function closeModal() {
    // //for test
    // $modalOpen.on("click", function () {
    //   if (!$body.hasClass('show-modal')) {
    //     $body.addClass('show-modal');
    //   } else {
    //     $body.removeClass('show-modal');
    //
    //   }
    // });
    // // for test end
    $modalClose.on("click", function () {
      if (!$body.hasClass('show-modal')) {
        $body.addClass('show-modal');

      } else {
        $body.removeClass('show-modal');
      }
    });
    $bgClose.on("click", function () {
      if (!$body.hasClass('show-modal')) {
        $body.addClass('show-modal');

      } else {
        $body.removeClass('show-modal');
      }
    });
  }
  formSend();
  closeModal();
}
