export default function () {
    console.log('header');
    let $body = $('body'),
      $this,
      $navLinkSub = $body.find('.nav-link-arrow--js'),
      $navli = $body.find('.nav-li'),
      $navUlSub = $navli.find('.nav-ul-sub'),
      $header = $body.find(".header"),
      $burgerBtn = $header.find(".burger--js"),
      $burgerMenu = $header.find(".header-mobile");

  function clickNav() {
    console.log('click');
    $navLinkSub.on("click",function(e) {
      e.preventDefault();
      $this = $(this);
      if (!$this.closest($navli).hasClass('open')) {
        $this.addClass('open').closest($navli).addClass('open').find($navUlSub).slideToggle("slow");
      } else {
        $this.removeClass('open').closest($navli).removeClass('open').find($navUlSub).slideToggle("slow");
      }
    });
  }

  function openMenu() {
    $burgerBtn.on("click", function () {
      if (!$burgerBtn.hasClass('is-active')) {
        $burgerBtn.addClass('is-active');
        $burgerMenu.addClass('menu-opened');
        $body.addClass('open-menu');
      } else {
        $burgerBtn.removeClass('is-active');
        $burgerMenu.removeClass('menu-opened');
        $body.removeClass('open-menu');
      }
    });
  }
  openMenu();

  // Header fixed on scroll
  function fixedHeader() {
    let mywindow = $(window),
        mypos = mywindow.scrollTop(),
        newscroll;
        mywindow.scroll(() => {
          newscroll = mywindow.scrollTop();
            if (newscroll > mypos && newscroll >= 300) {
              $header.addClass("menu-fixed")
            } else if (newscroll < mypos && newscroll < 300) {
              $header.removeClass("menu-fixed");
            }
          mypos = newscroll;
        });
  }
  fixedHeader();

  if (screen.width <= '768') {
    clickNav();
  }


}
