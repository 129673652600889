
export default function () {
  let $body = $('body'),
    $thisItem,
    $thisId,
    $priceList = $body.find('.price-list--js'),
    $priceHead = $body.find('.price-head--js'),
    $priceTable = $body.find('.price-table--js');


  function priceTabs() {
    console.log('priceTabs');
    $priceHead.on("click", function(e){
      $thisItem = $(this).closest($priceList);
      if (!$thisItem.hasClass('open')) {
        $thisItem.addClass('open').find($priceTable).slideToggle("slow");
      }
      else{
        $thisItem.removeClass('open').find($priceTable).slideToggle("slow");
      }
    });
  }
  function linkHash() {
    let myHash = location.hash;
    if(myHash[1] != undefined){
      $thisId = $body.find('[data-id = "' +myHash+ '"]');
      $thisId.closest($priceList).addClass('open').find($priceTable).slideToggle("slow");
      // console.log(myHash)
    }
    else{
      return false;
    }
  }
  linkHash();
  priceTabs();


}
