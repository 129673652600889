global.$ = global.jQuery = require('jquery');
import ContactsSection from "./Front/components/ContactsSection";
import Header from "./Front/components/Header";
(function() {

    Header();
    ContactsSection();
    // Initiate sections. Each function is a page/component on the website.
    if (document.getElementById("p-home")) require('./Front/pages/Home').default();
    if (document.getElementById("p-service")) require('./Front/pages/Service').default();
    if (document.getElementById("p-price")) require('./Front/pages/Price').default();

}());