import Slick from 'slick-carousel';

export default function () {
  let $body = $('body'),
    $mainSliderBlock = $body.find('.main-slider-block--js'),
    $ourClientsSlider = $body.find('.our-clients-slider--js'),
    $slideHidden = $body.find('.slide-hidden');

  function slideHidden() {
    $slideHidden.each(function () {
      let $this = $(this);
      $this.removeClass('slide-hidden');
    });
  }

  function mainSlider() {
    $mainSliderBlock.slick({
      dots: true,
      infinite: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 1000,
      cssEase: 'cubic-bezier(0.65, 0.05, 0.36, 1)',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: false
          }
        }
      ]
    });
  }

  function partnersSlider() {
    $ourClientsSlider.slick({
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      centerPadding: 0,
      cssEase: 'cubic-bezier(0.65, 0.05, 0.36, 1)',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });
  }

  slideHidden();
  mainSlider();
  partnersSlider();
}
