import Slick from 'slick-carousel';

export default function () {
  let $body = $('body'),
    $gallerySlider = $body.find('.gallery-slider--js'),
    $slideHidden = $body.find('.slide-hidden');
  function slideHidden(){
    $slideHidden.each(function () {
      let $this = $(this);
      $this.removeClass('slide-hidden');
    });
  }
  function gallerySlider() {
    $gallerySlider.slick({
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      // centerMode: true,
      // centerPadding: 0,
      // cssEase: 'cubic-bezier(0.65, 0.05, 0.36, 1)',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            slidesToShow: 2
          }
        }
      ]
    });
  }
  slideHidden();
  gallerySlider();
}
